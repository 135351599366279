<template>
  <section class="home">
    <nav>
      <div class="container">
        <div class="row">
          <div class="column column-12 column-xl-10 m-auto">
            <div class="banner">
              <nav class="nav-brand-mobile">
                <img src="../assets/image/logo/brand-text-white-logo.svg" alt="brand-text-logo">
                <p>品牌資源中心</p>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <main>
      <div class="container">
        <section class="slogan">
          <div class="row">
            <div class="column column-12 column-xl-10 m-auto">
              <div class="slogan-info">
                <div class="slogan-bg">
                  <img src="../assets/image/bg-text.svg" alt="">
                </div>
                <div class="solgan-text">
                  <h2 class="title-primary">
                    <span>Ocard 讓饗樂變成一種生活風格，無論是店家或使用者皆能輕鬆、方便地享受科技的便利！</span>
                  </h2>
                  <small>請選擇產品來查看相關資源</small>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="card">
          <ul class="card-group row">
            <li class="card-grid column column-6 column-md-4 column-xl-3">
              <router-link class="card-bg" to="/ocard">
                <div class="card-style card-bg-image card-animate">
                  <div class="card-body">
                    <img class="card-logo" src="../assets/image/logo/Ocard-logo.svg" alt="">
                    <h4 class="card-title h4">生活饗樂平台</h4>
                    <img class="card-link" src="../assets/image/Icon/Arrow-Right.svg" alt="">
                  </div>
                </div>
              </router-link>
            </li>
            <li class="card-grid column column-6 column-md-4 column-xl-3">
              <router-link class="card-bg" to="/ocard-for-business">
                <div class="card-style card-bg-image card-animate">
                  <div class="card-body">
                    <img class="card-logo" src="../assets/image/logo/OFB-logo.svg" alt="Ocard Logo">
                    <h4 class="card-title h4">顧客經營管家</h4>
                    <img class="card-link" src="../assets/image/Icon/Arrow-Right.svg" alt="">
                  </div>
                </div>
              </router-link>
            </li>
            <li class="card-grid column column-6 column-md-4 column-xl-3">
              <router-link class="card-bg" to="/ocard-rewards">
                <div class="card-style card-bg-image card-animate">
                  <div class="card-body">
                    <img class="card-logo" src="../assets/image/logo/OR-logo.svg" alt="Ocard Logo">
                    <h4 class="card-title h4">點數饗樂聯盟</h4>
                    <img class="card-link" src="../assets/image/Icon/Arrow-Right.svg" alt="">
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </section>
      </div>
    </main>
    <footer>
      <section class="copyright">
        <div class="container">
          <p>Ocard © 2021</p>
        </div>
      </section>
    </footer>
  </section>
</template>

<script>
export default {
  mounted() {
    document.title = 'Ocard Brand Resource Center';
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/all';

.banner {
  position: relative;
  width: 100%;
  height: 354px;
  border-radius: $border-radius-md;
  background-image: url(../../public/images/banner.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -25px;
  margin-bottom: 42px;
  @include media-breakpoint-md {
    height: 400px;
    margin-bottom: 48px;
  }
  @include media-breakpoint-lg {
    height: 500px;
    margin-top: 0;
    margin-bottom: 80px;
  }
  @include media-breakpoint-xl {
    height: 600px;
    border-radius: $border-radius-lg;
    margin-bottom: 72px;
  }
  @include media-breakpoint-xxl {
    height: 756px;
  }

  .nav-brand-mobile {
    position: absolute;
    bottom: 50%;
    left: 16px;
    transform: translateY(50%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-md {
      left: 32px;
    }
    @include media-breakpoint-xl {
      display: none;
    }

    img {
      margin-right: 9px;
    }

    p {
      position: relative;
      font-family: $font-family;
      font-size: 24px;
      font-weight: $font-weight-normal;
      color: white;
      top: 1px;
      white-space: nowrap;
    }
  }
}

.slogan {
  position: relative;

  .slogan-bg {
    position: absolute;
    width: 100%;
    top: -32px;
    left: 0;
    overflow: hidden;
    z-index: -1;
    @include media-breakpoint-md {
      top: -32px;
    }
    @include media-breakpoint-lg {
      top: -48px;
    }
    @include media-breakpoint-xl {
      width: 100%;
      top: -62px;
    }

    img {
      width: clamp(474px, 100%, 1420px);
      padding-left: 5px;
      @include media-breakpoint-xl {
        padding-left: 45px;
      }
    }
  }

  .solgan-text {
    margin-left: 0;
    margin-bottom: 32px;
    @include media-breakpoint-lg {
      margin-left: 32px;
      margin-bottom: 64px;
    }
    @include media-breakpoint-xxl {
      margin-left: 72px;
      margin-bottom: 64px;
    }

    h2 {
      margin-bottom: 22px;
      @include media-breakpoint-lg {
        margin-bottom: 32px;
      }
      @include media-breakpoint-xl {
        margin-bottom: 64px;
      }
    }

    span {
      display: inline;
      @include media-breakpoint-lg {
        display: block;
      }

      &:first-of-type {
        margin-bottom: 0;
        @include media-breakpoint-xl {
          margin-bottom: 24px;
        }
      }
    }

    small {
      font-size: $font-size-base;
      font-family: $font-family;
      font-weight: $font-weight-normal;
      line-height: $line-height-sm;
      opacity: 0.5;
      @include media-breakpoint-lg {
        font-size: $font-size-lg;
      }
    }
  }
}

.card-group {
  justify-content: space-between;

  .card-grid {
    margin-bottom: 32px;

    &:first-of-type {
      margin-left: 0;
      padding-right: 16px / 2;
      .card-bg-image {
        &:after {
          background-image: url(../../public/images/home-card-Ocard-bg.jpg);
        }
      }
    }

    &:nth-of-type(2) {
      padding-left: 16px / 2;
      .card-bg-image {
        &:after {
          background-image: url(../../public/images/home-card-OFB-bg.jpg);
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
      padding-right: 16px / 2;
      .card-bg-image {
        &:after {
          background-image: url(../../public/images/home-card-OR-bg.jpg);
        }
      }
    }

    @include media-breakpoint-md {
      margin-bottom: 0;

      &:first-of-type {
        padding-right: $gutter;
      }

      &:nth-of-type(2) {
        padding-right: $gutter;
        padding-left: $gutter;
      }

      &:last-of-type {
        padding-left: $gutter;
      }
    }

    @include media-breakpoint-xl {
      &:first-of-type {
        margin-left: calc(100% / 12 + 16px);
        padding-right: 0;
        padding-left: 0;
      }

      &:nth-of-type(2) {
        padding-right: 0;
        padding-left: 0;
      }

      &:last-of-type {
        margin-right: calc(100% / 12 + 16px);
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .card-style {
    position: relative;
    height: 210px;
    background: white;
    border-radius: $border-radius-md;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: $animation;
    box-shadow: $shadow;
    @include media-breakpoint-md {
      height: 320px;
    }
    @include media-breakpoint-lg {
      height: 450px;
    }
    @include media-breakpoint-xl {
      height: 600px;
      box-shadow: none;
      border-radius: $border-radius-lg;
    }
    @include media-breakpoint-xxl {
      height: 698px;
    }

    .card-body {
      z-index: 2;

      .card-logo {
        width: 101px;
        margin-bottom: 26px;
        @include media-breakpoint-lg {
          width: 196px;
          margin-bottom: 63px;
        }
      }

      .card-title {
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        color: $info;
        margin-bottom: 0;
        opacity: 1;
        @include media-breakpoint-lg {
          font-size: $font-size-lg;
        }
        @include media-breakpoint-xl {
          opacity: 0;
          margin-bottom: 80px;
        }
      }

      .card-link {
        display: none;
        width: 32px;
        @include media-breakpoint-xl {
          opacity: 0;
          display: inline-block;
        }
      }
    }
  }

  .card-bg {
    .card-bg-image {
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $border-radius-md;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        opacity: .05;
        transition: $animation;
        @include media-breakpoint-xl {
          border-radius: $border-radius-lg;
          opacity: 0;
        }
      }

      &:hover {
        &:after {
          opacity: .05;
        }
      }
    }
  }

  .card-animate {
    .card-logo {
      @include media-breakpoint-xl {
        transform: translateY(30%);
        transition: $animation;
      }
    }

    .card-title {
      @include media-breakpoint-xl {
        transform: translateY(10px);
        transition: $animation;
        transition-delay: .3s;
      }
    }

    .card-link {
      @include media-breakpoint-xl {
        transform: translateY(10px);
        transition: $animation;
        transition-delay: .4s;
      }
    }

    &:hover {
      box-shadow: $shadow;

      .card-logo {
        transform: translateY(0);
      }

      .card-title {
        opacity: 1;
        transform: translateY(0);
      }

      .card-link {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

}

.copyright {
  font-size: $font-size-sm;
  text-align: center;
  color: $gray;
  padding: 40px 0;
  @include media-breakpoint-xl {
    padding: 80px 0;
  }
}
</style>
